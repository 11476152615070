

.dark-theme {
    --background-color: black;
    --color: white;
}

.light-theme {
    --background-color: black;
    --color: rgb(18, 18, 18);
}


html,
body,
input,
select,
#dropdown,
.modal-content,
.pa-1.offcanvas.offcanvas-start.show,
.dropdown-menu.show{
    background-color: var(--background-color) !important;
    color: var(--color) !important;
    fill: var(--color) !important;
    border-color: var(--color) !important;
}