.et_pb_button{
    // border: 2px solid #fff;
    // padding: 10px;
    // background: transparent;
    // transition-duration: 2s;
    // transition-property: all !important;
    // position: relative;
    font-size: 20px;
    font-weight: 500;
    padding: 0.3em 1em;
    line-height: 1.7em!important;
    background-color: transparent;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    border: 2px solid #fff;
    border-radius: 3px;
    -webkit-transition-duration: .2s;
    transition-duration: .2s;
    -webkit-transition-property: all!important;
    transition-property: all!important;
    color: #fff !important;
    
}
.et_pb_promo_button{
    display: inline-block;
    color: inherit;
}
.et_pb_button:after, .et_pb_button:before {
    font-size: 32px;
    line-height: 1em;
    content: "\35";
    opacity: 0;
    position: absolute;
    margin-left: -1em;
    -webkit-transition: all .2s;
    transition: all .2s;
    text-transform: none;
    -webkit-font-feature-settings: "kern" off;
    font-feature-settings: "kern" off;
    font-variant: none;
    font-style: normal;
    font-weight: 400;
    text-shadow: none;
    font-family: "ETmoudles";
    color: #fff !important;
}
.et_pb_button:hover, .et_pb_module .et_pb_button:hover {
    border: 2px solid transparent;
    padding: 0.3em 2em 0.3em 0.7em;
    font-family: "ETmoudles";
}
.et_pb_promo_button:hover {
    text-decoration: none;
    font-family: "ETmoudles";
}
.et_pb_button:hover {
    background-color: #ffffff33;
    font-family: "ETmoudles";
    color: #fff !important;
}
.et_pb_promo_button:hover:after {
    opacity: 1;
    margin-left: 0;
    font-family: "ETmoudles";
}
.contact-button:hover{
    border: 2px solid transparent;
    padding: 0.3em 2em 0.3em 0.7em;
    text-decoration: none;
    background-color: hsla(0,0%,100%,.2);
}
.contact-button::after{
    font-size: 32px;
    line-height: 1em;
    content: "\35";
    opacity: 0;
    position: absolute;
    margin-left: -1em;
    -webkit-transition: all .2s;
    transition: all .2s;
    text-transform: none;
    -webkit-font-feature-settings: "kern" off;
    font-feature-settings: "kern" off;
    font-variant: none;
    font-style: normal;
    font-weight: 400;
    text-shadow: none;
}