.main-img{
    background-image: url("../Image/image1.jpeg");
    background-size: cover !important;
    background-position: center !important;
    // width: 100%;
    height: 80vh;
}
.section-white{
    background: #fff !important;
    
}
.section-black{
    background: #000 !important;
}
.section-grey{
    background: #F7F7F7 !important;
}
.section-dark-grey{
    background: #111111 !important;
}
.section-blue{
    background: #0090e9 !important;
}
.box{
    border: 2px solid #000;
    padding: 2rem;
    
}
.servi-border{
    border-bottom: 2px solid #9c9a9a;
}

@media screen and (max-width: 599px) {
    .vision{
        margin-left: 0px !important;
        margin-top: 1rem !important;
    }
    .techinical-section{
        margin-top: 2rem ;
    }
    .box{
        margin-top: 1rem ;
        margin-right: 0px !important;
    }
    .footer-build{
        text-align: center;
        margin-bottom: 3rem;
    }
    .insta-follow{
        margin-top: 3rem ;
    }
    .design-by{
        float: none !important;
        margin-top: 1rem;
    }
    .association{
        text-align: center;
    }
    .our-service{
        text-align: center;
    }
}
.hamburger{
    color: #0090e9;
    font-size: 2rem ;
}

@media screen and (max-width: 767px ) {
    .main-img{
       height: 10vh;
    }
}

// ----------------------NAVBAR--------------------------
.home-select::selection{
    background: #9c9a9a !important;
    padding: 10px !important;
    border-radius: 10px !important;
}