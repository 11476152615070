button.menu-button-mobile.m-l-1.btn.btn-primary,
button.menu-button-mobile.m-l-1.btn.btn-primary:hover {
    background-color: transparent !important;
    color: black !important;
    border: none;
    font-size: 1.5rem;
}
.primary-button{
    padding:0.5rem 2rem !important;
    background-color: transparent !important;
    color: inherit !important;
    float: right;
    margin-top: 2rem !important;
}