.emai-box{
    border-radius: 25px;
    background: #000;
}
.email-icon{
    border-radius: 50px;
    padding: 8px; 
    background: #000;
    color: white !important;
}

.contact-img{
 filter: brightness(10%);
}
@media screen and (max-width: 767px) {
    .contactimg{
        display: none;
    }
}