@media screen and (max-width : 767px ) {
    .footer-re{
        text-align: center;
    }
    .footer-build{
        margin-bottom: 2rem ;
    }
    .footer-add{
        margin-bottom: 2rem;
    }
}