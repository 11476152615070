.project-img{
    background-image:linear-gradient(120deg,#000000 0%,rgba(0,0,0,0) 100%) ,url("../Image/image1.jpeg");
    background-size: cover !important;
    background-position: center !important;
    height: 80vh;
    
   
}
.project-second{
    background-image: url("../Image/image6.jpeg");
    background-size: cover !important;
    width: 100%;
    height: 1000px;
}
.content{
    // display: block !important;
    // margin: auto !important;
    text-align: center;
    padding-top: 300px;
}
.project-fonts{
    font-family: 'Buenard', serif !important;
}
@media screen and (max-width: 767px) {
    .projec-img{
        margin-top: 2rem !important;
    }

}